import React from "react";
import { navigate } from "gatsby";

import Layout from "../../components/layout";
import { BACKEND_BASE_URL } from "../../constant/constants";
import { BriefSection, NewsEventSlider, Dropdown, Seo } from "../../components";

const BoardInfo = ({ selected_board, board_info, boards_index }) => {
  const onChange = e => {
    navigate("/boards/" + e.target.value);
  };

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Dropdown
              data={boards_index}
              selected={selected_board}
              onChange={onChange}
            />
            <>
              <BriefSection data={board_info} isProfileCard={false} />
              <NewsEventSlider />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const BoardBySlug = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.board_info.name}
        description={serverData.board_info.markdown}
        image_url={serverData.board_info.image_url}
      />
      <BoardInfo
        selected_board={serverData.board_info.slug}
        board_info={serverData.board_info}
        boards_index={serverData.boards_index}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const board_info_res = await fetch(
      BACKEND_BASE_URL + "boards/" + context.params.slug
    );
    const boards_index_res = await fetch(BACKEND_BASE_URL + "boards");
    if (!board_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!boards_index_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        board_info: await board_info_res.json(),
        boards_index: await boards_index_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default BoardBySlug;
